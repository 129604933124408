import axios, { AxiosPromise, AxiosResponse } from "axios";

export interface UniqueId {
  id: string;
}

// @ts-ignore eslint-disable-next-line
export interface DateAble {
  datePosted: Date;
  dateModified: Date;
}

export interface EntryRecord extends UniqueId, DateAble {}

export const loadArrayDataFromJson = <T>(
  json: string | any[],
  sorted = true
): T[] => {
  let dataArray;

  if (typeof json === "string") {
    const parsed = JSON.parse(json);
    if (!Array.isArray(parsed)) {
      return [] as T[];
    }
    dataArray = parsed;
  } else {
    dataArray = json;
  }

  if (!sorted) {
    return dataArray as T[];
  }
  dataArray = dataArray.map((el: any) => {
    el.datePosted = new Date(el.datePosted);
    el.dateModified = new Date(el.dateModified);
    return el;
  });

  return dataArray.sort((a: EntryRecord, b: EntryRecord) => {
    return b.datePosted.getTime() - a.datePosted.getTime();
  }) as T[];
};

type JsonDataType = "news" | "images" | "projects" | "skills" | "events";

export const loadJson = <T>(type: JsonDataType): Promise<T[]> => {
  return axios.get(`/data/${type}/${type}.json`).then(({ data }) => data);
};

export const loadData = async <T>(path: JsonDataType): Promise<T[]> =>
  loadJson<T>(path).then(response => {
    return loadArrayDataFromJson<T>(response, true);
  });
